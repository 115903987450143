import React from "react";
import PropTypes from 'prop-types';
import { Link } from "@lmig/lmds-react";
import Image from '@lmig/dotcom-aspect-components/Utility/Image';
import css from 'styled-jsx/css';

const InsurancePartners = ({
  heading,
  text,
  linkText = '',
  linkHref = '',
  linkDomain = 'COMPARION',
  partners = []
}) => {
  const { className: addPartnerClass, styles: addPartnerStyles } = css.resolve`
    img{
      padding: 1.25rem;
    }
  `;
  const { className: linkClass, styles: linkStyles } = css.resolve`
  .lm-Link{
    padding-top: 1rem;
  }
`;

  return (
    <div className="insurancePartnersWrapper">
      <div className="copyWrapper">
        {heading &&
          <div>{heading}</div>
        }
        <div className='infoText'>
          {text}
          {linkText && <Link className={linkClass} href={linkHref} linkDomain={linkDomain} variant="standalone">{linkText}</Link>}
        </div>
      </div>
      <ul className="partners">{
        partners.map(({ src, alt, width, height, downSize }) => (
          <li key={alt} style={{ height: `58px`, width: '128px' }}>
            <Image imageDomain="IMAGE" preload className={downSize ? addPartnerClass : ""}>
              <img
                className="partnerImage"
                src={`${src}`}
                alt={alt}
                width={width}
                height={height}
              />
            </Image>
          </li>
        ))}
      </ul>
      {addPartnerStyles}
      {linkStyles}
      <style jsx>{`
        @import './global-styles/tokens';
        .insurancePartnersWrapper {
          display: flex;
          flex-flow: column;
          align-items: center;
          @media screen and (min-width: 72.5rem) {
            flex-flow: row;
          }
        }
        .partners{
          list-style: none;
          padding: 0;
          display: flex;
          flex-flow: row wrap;
          justify-content: center;    
          align-items: center;  
          max-width: 33rem;
          margin: 2rem 0 0 0;
          @media screen and (min-width: $lm-breakpoint-lg-min) {
            margin: 2rem 0.5rem 1rem 4rem;
          }
          @media screen and (min-width: 42.5rem) {
            max-width: 33rem;
          }
          li {
            padding: 0.5rem;
            width: 50%;
            max-width: 10rem;

            @media screen and (min-width: 37.5rem) {
              max-width: 10rem;
            }
            @media screen and (min-width: 42.5rem) {
              max-width: 8rem;
            }
            @media screen and (min-width: 58rem) {
              max-width: 10rem;
            }
          }
        }
        .partnerImage{
          width: 100%;
          height: auto;
          max-width: min(100%,12.5rem);
          max-height: min(100%,12.5rem);
          object-fit: contain;
        }
        .infoText{
          text-align: center;
          max-width: 47.625rem;
          padding-top: 1rem;
        }
        .copyWrapper{
          max-width: 35rem;
          width: 100%;
          padding: 0 2rem 2rem 2rem;
          border-radius: 1rem;
          background-color: #F2FCFC;
        }
      `}</style>
    </div >
  );
};

InsurancePartners.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
  linkDomain: PropTypes.string,
  partners: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    downSize: PropTypes.bool,
  })),
};

export default InsurancePartners;
